import withTrans from '@/components/common/hocs/withTrans';
import { observer } from 'mobx-react-lite';
import PageTitle from '@/components/common/pages/PageTitle';
import { Button } from '@/components/ui/button';
import { Form } from '@/components/ui/form';
import PageComponent from '@/components/common/pages/PageComponent';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useGlobalStore } from '@/store/global/GlobalStore';
import { ReportSchema } from '@/schema/reportSchema';
import { useNavigate } from 'react-router';
import { toast } from '@/components/ui/use-toast';
import { createContacReport } from '@/modules/services/contact.service';
import { createRequestReport } from '@/modules/services/request.service';
import { createApplicationReport } from '@/modules/services/application.report.service';
import { CATEGORIES, REPORT_ROUTES } from '@/constants/appConstants';
import ReportForm from '@/pages/reports/form/ReportForm';
import { ReportPageProps } from '@/interfaces/report';
import { convertReportDataSub, handleErrorMessage } from '@/lib/helper';
import { useQueryClient } from '@tanstack/react-query';

const functionData = {
    [REPORT_ROUTES.CONTACT]: createContacReport,
    [REPORT_ROUTES.REQUEST]: createRequestReport,
    [REPORT_ROUTES.COMMUNICATION_APP]: createApplicationReport,
};

const CreateReport = ({ t, categoryType, is_welfare = false }: ReportPageProps) => {
    const { isLoading, setLoading } = useGlobalStore();
    const navigate = useNavigate();
    const type = REPORT_ROUTES[categoryType];
    const schema = ReportSchema(type);
    const queryClient = useQueryClient();

    const form = useForm<z.infer<typeof schema>>({
        resolver: zodResolver(schema),
        defaultValues: {
            assignee_id: undefined,
            category_id: undefined,
            category_child_id: undefined,
            content: undefined,
            google_map: {},
            attachments: [
                { file: undefined },
                { file: undefined },
                { file: undefined },
                { file: undefined },
                { file: undefined },
            ],
            isValidChild: false,
        },
        mode: 'onChange',
    });

    const onSubmit = async (value: any) => {
        try {
            setLoading(true);
            const formData: any = convertReportDataSub({ name: `report_${type}`, value });

            if (type === REPORT_ROUTES.REQUEST) {
                formData[`report_${type}`] = {
                    ...formData[`report_${type}`],
                    is_welfare,
                };
            }

            const res = await functionData[type](formData);
            if (res) {
                toast({ description: t('common.MSG_015'), variant: 'success' });
                onBack();
            }
        } catch (error) {
            const isError = handleErrorMessage({
                error: error?.errors?.assignee?.[1] || '',
                callback: () => {
                    form.setValue('assignee_id', undefined);
                    queryClient.refetchQueries({ queryKey: ['getOptionUsers', '', 1] });
                },
            });

            if (isError) return;

            handleErrorMessage({
                error: error?.errors?.category?.[1] || '',
                callback: () => {
                    form.setValue('category_id', undefined);
                    queryClient.refetchQueries({
                        queryKey: ['getCategoryOptions', '', 1, CATEGORIES[categoryType], is_welfare],
                    });
                },
                defaultMessage: 'common.MSG_038',
            });
        } finally {
            setLoading(false);
        }
    };

    const onBack = () => {
        is_welfare ? navigate(`/welfare/reports-request`) : navigate(`/communication/reports/${type}`);
    };

    return (
        <PageComponent
            title={
                <PageTitle
                    routes={[
                        {
                            path: '',
                            breadcrumbName: t(`report.${type}_title_create`),
                        },
                    ]}
                />
            }
            pageFooter={
                <div className="flex gap-4 justify-center mt-8">
                    <Button
                        type="submit"
                        variant="default"
                        size="xl"
                        className="text-[19px] h-[42px] w-44"
                        onClick={form.handleSubmit(onSubmit, (error) => console.log(error))}
                        disabled={isLoading}
                    >
                        {t('register')}
                    </Button>
                    <Button variant="outline" size="xl" className="text-[19px] h-[42px] w-44" onClick={onBack}>
                        {t('go_back')}
                    </Button>
                </div>
            }
        >
            <Form {...form}>
                <ReportForm categoryType={categoryType} type="created" is_wefare={is_welfare} />
            </Form>
        </PageComponent>
    );
};
export default withTrans(observer(CreateReport));
