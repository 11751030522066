import withTrans from '@/components/common/hocs/withTrans';
import { observer } from 'mobx-react-lite';
import PageTitle from '@/components/common/pages/PageTitle';
import PageComponent from '@/components/common/pages/PageComponent';
import { useNavigate, useParams } from 'react-router';
import { getContactReportDetail, createFileContactProgressCsv } from '@/modules/services/contact.service';
import { getRequestReportDetail, createFileRequestProgressCsv } from '@/modules/services/request.service';
import {
    getReportApplicationDetail,
    createFileApplicationProgressCsv,
} from '@/modules/services/application.report.service';
import { useQuery } from '@tanstack/react-query';
import ReportDetailComponent from '@/components/common/pages/ReportDetail';
import { ReportDetailType, ReportPageProps } from '@/interfaces/report';
import { REPORT_ROUTES } from '@/constants/appConstants';
import { convertFileNameCsv } from '@/lib/helper';
import { toast } from '@/components/ui/use-toast';
import { useCsvStore } from '@/store/csv/CsvStore';

const functionData = (is_welfare: boolean) => ({
    [REPORT_ROUTES.CONTACT]: {
        get: getContactReportDetail,
        dowloadCsv: createFileContactProgressCsv,
    },
    [REPORT_ROUTES.REQUEST]: {
        get: (id: string) => getRequestReportDetail(id, is_welfare),
        dowloadCsv: createFileRequestProgressCsv,
    },
    [REPORT_ROUTES.COMMUNICATION_APP]: {
        get: getReportApplicationDetail,
        dowloadCsv: createFileApplicationProgressCsv,
    },
});

const ReportDetail = ({ t, categoryType, is_welfare = false }: ReportPageProps) => {
    const navigate = useNavigate();
    const requestFunction = functionData(is_welfare);
    const { id } = useParams();
    const { addQueue } = useCsvStore();
    const type = REPORT_ROUTES[categoryType];

    const onBack = () => {
        is_welfare ? navigate(`/welfare/reports-request`) : navigate(`/communication/reports/${type}`);
    };

    const getDetailReport = async (id: string) => {
        try {
            const res = await requestFunction[type].get(id);
            return res.data as ReportDetailType;
        } catch (error) {
            return null;
        }
    };

    const { isFetching: isLoading, data } = useQuery({
        queryKey: ['getDetailReport', id],
        queryFn: () => {
            if (id) {
                return getDetailReport(id);
            }
        },
    });

    const onDownloadCsv = async () => {
        try {
            await requestFunction[type].dowloadCsv(id);
            addQueue({
                flag: `${type}_report_process`,
                status: 'pending',
                fileName: convertFileNameCsv({
                    prefixName: t(`report.${type}_title_detail`),
                }),
                url: `users/report_${type}s/${id}/export_csv_report_${type === 'application' ? 'app' : type}_progress`,
            });
            toast({ description: t('common.MSG_045'), variant: 'success' });
        } catch (error) {
            toast({ description: t('common.MSG_040'), variant: 'destructive' });
        }
    };

    return (
        <PageComponent
            pageLoading={isLoading}
            title={
                <PageTitle
                    routes={[
                        {
                            path: '',
                            breadcrumbName: t(`report.${type}_title_detail`),
                        },
                    ]}
                />
            }
            onBack={onBack}
            textBack={t('go_back')}
            onDownLoadCsv={onDownloadCsv}
        >
            <ReportDetailComponent data={data} categoryType={type} isWelfare={is_welfare} />
        </PageComponent>
    );
};
export default withTrans(observer(ReportDetail));
