import withTrans, { WithTransProps } from '@/components/common/hocs/withTrans';
import PageComponent from '@/components/common/pages/PageComponent';
import PageTitle from '@/components/common/pages/PageTitle';
import { Button } from '@/components/ui/button';
import { toast } from '@/components/ui/use-toast';
import { Form } from '@/components/ui/form';
import { observer } from 'mobx-react-lite';
import { useNavigate, useParams } from 'react-router';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { CategoryChildSchema, CategorySchema } from '@/schema/phrasesSchema';
import { useAuthenStore } from '@/store/auth/AuthStore';
import { useGlobalStore } from '@/store/global/GlobalStore';
import { CategoryService } from '@/modules/services';
import { CATEGORY_TABS, Category, CategoryTypeReq } from '@/interfaces/category';
import { toCategoryType } from '@/lib/utils';
import FormInputText from '@/components/common/form/FormInputText';
import FormSelectMultipleValue from '@/components/common/form/FormSelectMultipleValue';
import { getChildrenCategoryList } from '@/modules/services/category.service';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { GetListUsers } from '@/modules/services/user.service';
import { useSearchParams } from 'react-router-dom';
import { PropsWithWelfare, SelectOptionsItem } from '@/interfaces/common/global';
import SelectBox from '@/pages/categories/components/SelectBox';
import { handleErrorMessage } from '@/lib/helper';
import { SelectedOption } from '@/pages/meeting/form/MeetingForm';
import { useMemo, useState } from 'react';
import _ from 'lodash';

const CreateCategory = (props: WithTransProps & PropsWithWelfare) => {
    const { t, is_welfare = false } = props;
    const { type } = useParams<{ type: Category }>();
    const { user } = useAuthenStore();
    const navigate = useNavigate();
    const { isLoading, setLoading } = useGlobalStore();
    const [searchParams] = useSearchParams();
    const queryClient = useQueryClient();
    const [options, setOptions] = useState<any[]>([]);

    const categoryType = searchParams.get('type') === CATEGORY_TABS.CHILD ? CATEGORY_TABS.CHILD : CATEGORY_TABS.PARENT;

    const form = useForm({
        resolver: async (data, context, options) => {
            if (categoryType === CATEGORY_TABS.CHILD) {
                return zodResolver(CategoryChildSchema)(data, context, options);
            }
            return zodResolver(CategorySchema)(data, context, options);
        },
        defaultValues: {
            content: undefined,
            child_category: [],
        },
        mode: 'all',
    });

    const onBackMenu = () => {
        navigate(
            is_welfare
                ? `/welfare/categories/${type}?type=${categoryType}`
                : `/communication/categories/${type}?type=${categoryType}`
        );
    };

    const getMediumOptions = async () => {
        try {
            const res = await getChildrenCategoryList({
                type: toCategoryType(type) as Category,
                per_page: 1000,
                order_by: 'content asc',
                is_welfare,
            });
            // format data response to optionProps type
            if (!res?.data?.length) {
                throw new Error();
            }
            const newOptions = res?.data.map((acc: any) => ({ label: acc.content, value: acc.id }));
            form.setValue('child_category', []);
            setOptions(newOptions);
            return newOptions;
        } catch (error) {
            console.log(error);
            return [];
        }
    };

    const getListAccountLocalGov = async () => {
        try {
            const res: any = await GetListUsers({
                role: is_welfare ? 'local_gov_welfare' : 'local_gov_communication',
                order_by: 'name asc updated_at desc',
                is_show_current_user: true,
                per_page: 1000,
            });
            if (!res?.data?.length) {
                throw new Error();
            }
            const newOptions = res?.data.map((acc: any) => ({ label: acc.name, value: acc.id, email: acc.email }));

            const isMaster = res?.data.find((acc: any) => acc.email === process.env.REACT_APP_EMAIL_MASTER);

            if (isMaster) {
                form.setValue('child_category', [{ label: isMaster.name, value: isMaster.id }]);
            }
            setOptions(newOptions);
            return newOptions;
        } catch (error) {
            console.log(error);
            return [];
        }
    };

    const onSubmit = async () => {
        if (!user.id) return;

        try {
            const req = form.getValues();
            setLoading(true);
            const body: CategoryTypeReq = { content: req.content, type: toCategoryType(type) as Category, is_welfare };

            if (categoryType === CATEGORY_TABS.CHILD) {
                body.user_ids = req.child_category.map((item: SelectOptionsItem) => item.value);
            } else {
                body.category_child_ids = req.child_category.map((item: SelectOptionsItem) => item.value);
            }

            const res =
                categoryType === CATEGORY_TABS.CHILD
                    ? await CategoryService.createCategoryChild(body)
                    : await CategoryService.createCategory(body);
            if (res) {
                toast({ description: t('common.MSG_015'), variant: 'success' });
                onBackMenu();
            }
        } catch (error) {
            const isErrorCategory = handleErrorMessage({
                error: error?.errors?.category?.[0] || '',
                callback: (msg) => {
                    if (msg.includes('MSG_077') || msg.includes('MSG_076') || msg.includes('MSG_063')) {
                        queryClient.refetchQueries({ queryKey: ['getOptionsCategory', categoryType, is_welfare] });
                    }
                },
            });

            if (isErrorCategory) return;

            const message = error?.errors?.category_child?.[0] || '';
            handleErrorMessage({
                error: message,
                callback: () => {
                    if (message.includes('MSG_077') || message.includes('MSG_076') || message.includes('MSG_063')) {
                        queryClient.refetchQueries({ queryKey: ['getOptionsCategory', categoryType, is_welfare] });
                    }
                },
                defaultMessage: 'common.MSG_038',
            });
        } finally {
            setLoading(false);
        }
    };

    const { data: optionsData, isLoading: isLoadingOptions } = useQuery({
        queryKey: ['getOptionsCategory', categoryType, is_welfare],
        queryFn: () => (categoryType === CATEGORY_TABS.CHILD ? getListAccountLocalGov() : getMediumOptions()),
    });

    const handleSelectAll = (_: string, checked: boolean) => {
        form.setValue('child_category', checked ? optionsData : []);
    };

    const handleCheckboxChange = (checked: boolean, field: Record<'id', string> & SelectedOption) => {
        const childCategory = form.getValues('child_category');

        if (checked) {
            form.setValue('child_category', [...childCategory, { value: field.value, label: field.label }]);
        } else {
            form.setValue(
                'child_category',
                childCategory.filter((item: any) => item.value !== field.value)
            );
        }
    };

    const onHandleChange = (item: string, checked: boolean) => {
        if (item === 'all') {
            form.setValue(
                'select.' as any,
                form.getValues('select.' as any).map((e: any) => ({ ...e, checked }))
            );
        } else {
            const index = optionsData.findIndex((e: any) => e.value === item);
            form.setValue(`select.[${index}].checked` as any, checked);
        }
    };

    const handleSearch = _.debounce((name: string) => {
        if (_.isEmpty(name)) {
            setOptions(optionsData);
        } else {
            const newOptions = _.cloneDeep(optionsData);
            const afterFilterSections = newOptions?.filter(
                (option: any) => option.label.toLowerCase().indexOf(name.toLowerCase()) >= 0
            );
            setOptions(afterFilterSections);
        }
    }, 500);

    const onDropdownVisibleChange = (visible: boolean) => {
        if (!visible) {
            setOptions(optionsData);
        }
    };

    const optionsChild = useMemo(() => {
        if (optionsData?.length > 0) {
            return (
                optionsData.map((item: any) => ({
                    ...item,
                    checked:
                        CATEGORY_TABS.CHILD === categoryType
                            ? item.email === process.env.REACT_APP_EMAIL_MASTER
                            : false,
                })) || []
            );
        } else {
            return [];
        }
    }, [optionsData]);

    return (
        <PageComponent
            title={
                <PageTitle
                    routes={[{ path: '', breadcrumbName: t(`category.${categoryType}_${type}_new`) }]}
                    className="py-6"
                />
            }
            pageFooter={
                <div className="flex gap-4 justify-center mt-8">
                    <Button
                        type="submit"
                        variant="default"
                        size="xl"
                        className="text-ip h-[42px] w-44"
                        onClick={form.handleSubmit(onSubmit)}
                        disabled={isLoading}
                    >
                        {t('register')}
                    </Button>
                    <Button variant="outline" size="xl" className="text-ip h-[42px] w-44" onClick={onBackMenu}>
                        {t('go_back')}
                    </Button>
                </div>
            }
        >
            <Form {...form}>
                <FormInputText
                    className="py-4 mt-4"
                    name="content"
                    labelClass="min-w-[170px]"
                    label={t(
                        categoryType === CATEGORY_TABS.CHILD ? 'category.child_category' : 'category.parent_category'
                    )}
                    placeholder={t('common.MSG_001', {
                        field: t(
                            categoryType === CATEGORY_TABS.CHILD
                                ? 'category.child_category'
                                : 'category.parent_category'
                        ),
                    })}
                    maxLength={20}
                />
                <FormSelectMultipleValue
                    required={categoryType === CATEGORY_TABS.CHILD}
                    className="py-4 mt-4"
                    name="child_category"
                    labelClass="min-w-[170px]"
                    label={t(categoryType === CATEGORY_TABS.CHILD ? 'category.department' : 'category.child_category')}
                    placeholder={t('common.MSG_002', {
                        field: t(
                            categoryType === CATEGORY_TABS.CHILD ? 'category.department' : 'category.child_category'
                        ),
                    })}
                    options={options || []}
                    initOptions={optionsData}
                    onHandleChange={onHandleChange}
                    onSearch={handleSearch}
                    onDropdownVisibleChange={onDropdownVisibleChange}
                />
                <SelectBox
                    required={categoryType === CATEGORY_TABS.CHILD}
                    handleSelectAll={handleSelectAll}
                    onCheckboxChange={handleCheckboxChange}
                    isLoad={isLoadingOptions}
                    labelClass="min-w-[170px]"
                    sectionOptions={[{ label: '', options: optionsChild }]}
                />
            </Form>
        </PageComponent>
    );
};

export default withTrans(observer(CreateCategory));
