import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { ReportRoutes } from '@/interfaces/common/global';
import { ReportDetailType } from '@/interfaces/report';
import ProgressComponent from '@/components/common/pages/ProgressComponent';
import { REPORT_ROUTES } from '@/constants/appConstants';
import { Textarea } from '@/components/ui/textarea';
import {
    deleteProgressReportContact,
    getDetailProgressReportContact,
    updateProgressReportContact,
    createProgressReportContact,
} from '@/modules/services/contact.service';
import {
    getDetailProgressReportRequest,
    updateProgressReportRequest,
    deleteProgressReportRequest,
    createProgressReportRequest,
} from '@/modules/services/request.service';
import {
    createProgressReportApplication,
    getDetailProgressReportApplication,
    updateProgressReportApplication,
    deleteProgressReportApplication,
} from '@/modules/services/application.report.service';
import { useQueryClient } from '@tanstack/react-query';
import GoogleMapView from '@/components/common/pages/GoogleMapView';
import { CarouselComponent } from '@/components/common/pages/CarouseComponent';
import { useEffect, useRef } from 'react';
import { Item, WrapItem, renderFooterCarouse, renderItemCarouse } from '@/components/common/pages/Element';
import { APP_PERMISSION } from '@/constants/permissionConfig';
import { sortAttachments } from '@/lib/helper';
import { cn } from '@/lib/utils';

type Props<T> = {
    data: T;
    categoryType: ReportRoutes;
    isWelfare?: boolean;
};

const functionData = {
    [REPORT_ROUTES.CONTACT]: {
        getDetailProgress: getDetailProgressReportContact,
        updateProgress: updateProgressReportContact,
        deleteProgress: deleteProgressReportContact,
        createProgress: createProgressReportContact,
    },
    [REPORT_ROUTES.REQUEST]: {
        getDetailProgress: getDetailProgressReportRequest,
        updateProgress: updateProgressReportRequest,
        deleteProgress: deleteProgressReportRequest,
        createProgress: createProgressReportRequest,
    },
    [REPORT_ROUTES.COMMUNICATION_APP]: {
        getDetailProgress: getDetailProgressReportApplication,
        updateProgress: updateProgressReportApplication,
        deleteProgress: deleteProgressReportApplication,
        createProgress: createProgressReportApplication,
    },
};

export const ReportDetail = <T extends ReportDetailType>({ data, categoryType, isWelfare }: Props<T>) => {
    const { t } = useTranslation();
    const queryClient = useQueryClient();
    const mapRef = useRef(null);
    const attachments = sortAttachments(data?.attachments || []);

    const onRefreshData = () => {
        queryClient.refetchQueries({ queryKey: ['getDetailReport'] });
    };

    useEffect(() => {
        if (data) {
            // console.log(data);
            mapRef.current.setCurrentLocation({ lat: data?.latitude, lng: data?.longitude });
            const isMarker = data?.latitude && data?.longitude;
            isMarker && mapRef.current.setMarkers(isMarker ? [{ lat: data.latitude, lng: data.longitude }] : []);
        }
    }, [data]);

    const generateRole = () => {
        const roleType = isWelfare ? 'welfare' : 'communication';
        const role = data?.assignee?.roles?.[roleType]?.[0];

        if (!role) return '';

        const districtPrefix =
            roleType === 'communication' && role === APP_PERMISSION.MAYOR && data.assignee.district
                ? `${data.assignee.district} `
                : '';

        return `${districtPrefix}${t(`report.${role}`)}`;
    };

    return (
        <div>
            <WrapItem>
                <Item className=" flex-col xl:col-span-3 gap-3">
                    <Item
                        label={t(`report.${categoryType}_reporter_position`)}
                        data={generateRole()}
                        className="items-center"
                    />

                    <Item
                        label={t(`report.${categoryType}_reporter_name`)}
                        data={data?.assignee?.name}
                        className="items-center"
                    />
                    <Item
                        label={t('category.parent_category')}
                        data={data?.category?.content}
                        className="items-center"
                    />
                    {data?.category_child && (
                        <Item
                            label={t('report.category_medium')}
                            data={data?.category_child?.content}
                            className="items-center"
                        />
                    )}
                </Item>
                <Item
                    label={t(`report.${categoryType}_repost_content`)}
                    labelClass="mt-2 xl:min-w-[160px]"
                    className="lg:col-span-4"
                >
                    <Textarea value={data?.content} readOnly={true} rows={5} className="text-pc disabled:cursor-text" />
                </Item>
            </WrapItem>
            <WrapItem>
                <Item label={t('report.image')} className="lg:col-span-3 mb-4">
                    <CarouselComponent
                        carouselClass="w-[80%] xl:w-[90%]"
                        data={attachments}
                        isShowNext={attachments.length > 1}
                        isShowPrevious={attachments.length > 1}
                        renderFooter={renderFooterCarouse}
                        renderItem={(item) => renderItemCarouse(item)}
                    />
                </Item>
                <Item
                    label={t('report.address')}
                    labelClass="xl:min-w-[160px] mt-2"
                    className={cn('xl:col-span-4 xl:mt-0', attachments.length > 1 ? 'mt-[60px]' : '')}
                >
                    <div className="w-full">
                        {data?.address && (
                            <div className=" px-2 py-2 border border-grey rounded-md overflow-hidden text-ellipsis">
                                {data.address}
                            </div>
                        )}
                        <GoogleMapView
                            ref={mapRef}
                            multipleMaker={false}
                            loadCurrent={false}
                            zoom={16}
                            height={'300px'}
                            className="justify-normal mt-4 "
                            isView={true}
                            defaultPos={null}
                        />
                    </div>
                </Item>
            </WrapItem>
            <ProgressComponent
                status={data?.status}
                data={data}
                onGetDetailData={functionData[categoryType]?.getDetailProgress}
                onRemoveProccess={functionData[categoryType]?.deleteProgress}
                onCreateData={functionData[categoryType]?.createProgress}
                onUpdateData={functionData[categoryType]?.updateProgress}
                refreshData={onRefreshData}
            />
        </div>
    );
};
export default observer(ReportDetail);
