import { MenuButtonProps } from '@/interfaces/common/menu';
import { APP_PERMISSION } from '@/constants/permissionConfig';

export type KeyMenu = 'COMMUNICATION' | 'WELFARE' | 'ACCOUNT';

export const MODULE_MENU: { [K in KeyMenu]: string } = {
    COMMUNICATION: 'communication',
    WELFARE: 'welfare',
    ACCOUNT: 'accounts',
};

export const WELFARE: MenuButtonProps[] = [
    {
        key: 'welfare.confirm_status',
        label: 'welfare.confirm_status',
        children: [
            {
                key: 'welfare.list_visit_report',
                label: 'welfare.list_visit_report',
                path: 'visit-reports',
            },
            {
                key: 'welfare.request_list',
                label: 'welfare.request_list',
                path: 'reports-request',
            },
            {
                key: 'welfare.list_question_answer',
                label: 'welfare.list_question_answer',
                path: 'surveys',
            },
            {
                key: 'welfare.61_survey_list_answer',
                label: 'welfare.61_survey_list_answer',
                path: 'surveys?survey_type=survey_61',
            },
            {
                key: 'welfare.list_application_accepted',
                label: 'welfare.list_application_accepted',
                path: 'applications',
            },
        ],
    },
    {
        key: 'welfare.create_new',
        label: 'welfare.create_new',
        children: [
            {
                key: 'welfare.new_destination_registration',
                label: 'welfare.new_destination_registration',
                path: 'residents',
            },
            {
                key: 'welfare.create_request',
                label: 'welfare.create_request',
                path: 'categories/request',
            },
            {
                key: 'welfare.create_new_question',
                label: 'welfare.create_new_question',
                path: 'questions',
            },
            {
                key: 'welfare.ceate_new_61_survey',
                label: 'welfare.ceate_new_61_survey',
                path: 'questions?survey_type=survey_61',
            },
            {
                key: 'welfare.create_fixed_phrases',
                label: 'welfare.create_fixed_phrases',
                path: 'phrases',
            },
        ],
    },
    {
        key: 'welfare.document',
        label: 'welfare.document',
        children: [
            {
                key: 'welfare.manual',
                label: 'welfare.manual',
                path: 'documents',
            },
        ],
    },
];

export const COMMUNICATION: MenuButtonProps[] = [
    {
        key: 'communication.confirm_status',
        label: 'communication.confirm_status',
        children: [
            {
                key: 'communication.contact_report_list',
                label: 'communication.contact_report_list',
                path: 'reports/contact',
            },
            {
                key: 'communication.request_list',
                label: 'communication.request_list',
                path: 'reports/request',
            },
            {
                key: 'communication.application_accepted_list',
                label: 'communication.application_accepted_list',
                path: 'reports/application',
            },
        ],
    },
    {
        key: 'communication.create_category',
        label: 'communication.create_category',
        children: [
            {
                key: 'communication.create_communicate_report',
                label: 'communication.create_communicate_report',
                path: 'categories/contact',
            },
            {
                key: 'communication.create_request',
                label: 'communication.create_request',
                path: 'categories/request',
            },
            {
                key: 'communication.create_application_form',
                label: 'communication.create_application_form',
                path: 'categories/application',
            },
            {
                key: 'communication.create_meeting_request',
                label: 'communication.create_meeting_request',
                path: 'meeting',
            },
            {
                key: 'communication.create_fixed_phrases',
                label: 'communication.create_fixed_phrases',
                path: 'phrases',
            },
        ],
    },

    {
        key: 'communication.document',
        label: 'communication.document',
        children: [
            {
                key: 'communication.manual',
                label: 'communication.manual',
                path: 'documents',
            },
        ],
    },
];

export const WELFARE_COMMISSIONER: MenuButtonProps[] = [
    {
        key: 'commissioner',
        label: 'commissioner',
        children: [
            {
                key: 'commissioner.report',
                label: 'commissioner.report',
                path: 'commissioner/visit-reports',
                description: 'commissioner.report_note',
                col: 2,
            },
            {
                key: 'commissioner.destination',
                label: 'commissioner.destination',
                description: 'commissioner.first_time',
                path: 'commissioner/residents/create',
            },
            {
                key: 'commissioner.resident',
                label: 'commissioner.resident',
                path: 'commissioner/residents',
            },
            {
                key: 'commissioner.request',
                label: 'commissioner.request',
                path: 'commissioner/register-requests',
            },
            {
                key: 'commissioner.request_list',
                label: 'commissioner.request_list',
                path: 'commissioner/requests',
            },
            {
                key: 'commissioner.question',
                label: 'commissioner.question',
                path: 'commissioner/surveys',
            },
            {
                key: 'commissioner.answer_61_survey',
                label: 'commissioner.answer_61_survey',
                path: 'commissioner/surveys?survey_type=survey_61',
            },
            {
                key: 'commissioner.applications',
                label: 'commissioner.applications',
                path: 'commissioner/applications',
            },
            {
                key: 'commissioner.manual',
                label: 'commissioner.manual',
                path: 'commissioner/documents',
            },
        ],
    },
];

export const COMMUNICATION_MAYOR: MenuButtonProps[] = [
    {
        key: 'mayor',
        label: 'mayor',
        children: [
            {
                key: 'mayor.contact',
                label: 'mayor.contact',
                path: 'mayor/register-contact',
            },
            {
                key: 'mayor.contact_list',
                label: 'mayor.contact_list',
                path: 'mayor/contacts',
            },
            {
                key: 'mayor.request',
                label: 'mayor.request',
                path: 'mayor/register-requests',
            },
            {
                key: 'mayor.request_list',
                label: 'mayor.request_list',
                path: 'mayor/requests',
            },
            {
                key: 'mayor.application_list',
                label: 'mayor.application_list',
                path: 'mayor/applications',
            },
            {
                key: 'mayor.attendance',
                label: 'mayor.attendance',
                path: 'mayor/attendance-meetings',
            },
            {
                key: 'mayor.manual',
                label: 'mayor.manual',
                path: 'mayor/documents',
            },
        ],
    },
];

export const COMMUNICATION_REPRESENTATIVE: MenuButtonProps[] = [
    {
        key: 'representative',
        label: 'representative',
        children: [
            {
                key: 'representative.attendance',
                label: 'representative.attendance',
                path: 'representative/attendance-meetings',
            },
            {
                key: 'representative.manual',
                label: 'representative.manual',
                path: 'representative/documents',
            },
            {
                key: 'representative.request',
                label: 'representative.request',
                path: 'representative/requests/create',
            },
            {
                key: 'representative.request_list',
                label: 'representative.request_list',
                path: 'representative/requests',
            },
            {
                key: 'representative.application_list',
                label: 'representative.application_list',
                path: 'representative/applications',
            },
        ],
    },
];

export const MENU_TOOL: {
    [key: string]: { label: string; path: string; roles: string[]; menu: { [key: string]: any } };
} = {
    [MODULE_MENU.COMMUNICATION]: {
        label: 'home.communication',
        menu: {
            [APP_PERMISSION.LOCAl_GOV_COMMUNICATION]: COMMUNICATION,
            [APP_PERMISSION.MAYOR]: COMMUNICATION_MAYOR,
            [APP_PERMISSION.REPRESENTATIVE]: COMMUNICATION_REPRESENTATIVE,
        },
        path: MODULE_MENU.COMMUNICATION,
        roles: [APP_PERMISSION.LOCAl_GOV_COMMUNICATION, APP_PERMISSION.MAYOR, APP_PERMISSION.REPRESENTATIVE],
    },
    [MODULE_MENU.WELFARE]: {
        label: 'home.welfare',
        menu: {
            [APP_PERMISSION.LOCAL_GOV_WELFARE]: WELFARE,
            [APP_PERMISSION.COMMISSIONER]: WELFARE_COMMISSIONER,
        },
        path: MODULE_MENU.WELFARE,
        roles: [APP_PERMISSION.LOCAL_GOV_WELFARE, APP_PERMISSION.COMMISSIONER],
    },
    [MODULE_MENU.ACCOUNT]: {
        label: 'home.account',
        path: MODULE_MENU.ACCOUNT,
        menu: {},
        roles: [APP_PERMISSION.LOCAL_GOV_WELFARE, APP_PERMISSION.LOCAl_GOV_COMMUNICATION],
    },
};
