import withTrans from '@/components/common/hocs/withTrans';
import AppIconSvg from '@/assets/icons/app_icon.svg';
import { Button } from '@/components/ui/button';
import { PageProps } from '@/interfaces/props/page';
import { useLocation, useNavigate } from 'react-router';
import { observer } from 'mobx-react-lite';
import { useGlobalStore } from '@/store/global/GlobalStore';
import { MENU_TOOL } from '@/constants/menu';
import { useAuthenStore } from '@/store/auth/AuthStore';
import { toJS } from 'mobx';
import { ProfileHeader } from '@/components/common/header';
import { showModalConfirm } from '@/components/common/pages/ModalConfirm';
import { AuthService } from '@/modules/services';
import { toast } from '@/components/ui/use-toast';
import { useEffect, useState } from 'react';
import { cn } from '@/lib/utils';

const HomeComponent = ({ t }: PageProps) => {
    const navigate = useNavigate();
    const { setModule, setPrevious } = useGlobalStore();
    const { user, clearData } = useAuthenStore();
    const { pathname } = useLocation();
    const [isFirstLoad, setIsFirstLoad] = useState(true);

    useEffect(() => {
        setTimeout(() => {
            setIsFirstLoad(false);
        }, 700);
        return () => {
            setIsFirstLoad(true);
        };
    }, []);
    const roles = user ? toJS(user.roles) : [];

    const handleClick = (module: string) => () => {
        setModule(module);
        navigate(`/${module}`);
    };

    const handleClickProfile = () => {
        setPrevious({ profile: pathname });
        navigate(`/profile`);
    };

    const onClickLogout = async () => {
        const data = await showModalConfirm({ title: t('logout_title'), message: t('logout_message') });
        if (data) {
            try {
                await AuthService.Logout();
                clearData();
                navigate('/login');
                toast({ description: t('common.MSG_009'), variant: 'success' });
            } catch (error) {
                toast({ description: t('common.MSG_100'), variant: 'destructive' });
            }
        }
    };

    return (
        <div className="flex flex-col items-center gap-9 max-w-[800px] m-auto whitespace-pre-line pb-6 px-4 ">
            <img src={AppIconSvg} loading="lazy" className="w-[112px] sm:w-[160px] pt-14" alt="forgot password" />
            <div className="text-title font-bold text-center">{t('app_title')}</div>
            <ProfileHeader
                className="mb-4 smmb-6 md:mb-10"
                name={user?.name || user?.email}
                onClickProfile={handleClickProfile}
                onLogout={onClickLogout}
            />
            {Object.keys(MENU_TOOL)
                .filter((el: string) =>
                    MENU_TOOL[el].roles.reduce((curr: any, next: any) => curr || roles.includes(next), false)
                )
                .map((item: string) => (
                    <Button
                        key={MENU_TOOL[item].label}
                        variant="outline"
                        size="xxl"
                        className={cn(
                            'font-bold border-black w-full h-[94px]',
                            isFirstLoad ? ' pointer-events-none' : 'pointer-events-auto'
                        )}
                        onClick={handleClick(MENU_TOOL[item].path)}
                    >
                        {t(MENU_TOOL[item].label)}
                    </Button>
                ))}
        </div>
    );
};
export default withTrans(observer(HomeComponent));
