import withTrans from '@/components/common/hocs/withTrans';
import PageComponent from '@/components/common/pages/PageComponent';
import PageTitle from '@/components/common/pages/PageTitle';
import TableComponent from '@/components/common/table/TableComponent';
import useGetDataReport from '@/hooks/useGetDataReport';
import { ReportPageProps, ReportType } from '@/interfaces/report';
import { useAuthenStore } from '@/store/auth/AuthStore';
import { TableProps } from 'antd';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { useNavigate } from 'react-router';
import { columnsContact } from '@/pages/ipad-reports/components/Columns';
import { getContactReportListByUser } from '@/modules/services/contact.service';
import { getRequestReportListByUser } from '@/modules/services/request.service';
import { getApplicationReportListByUser } from '@/modules/services/application.report.service';
import { REPORT_ROUTES } from '@/constants/appConstants';
import { Params } from '@/interfaces/common/global';

const getReportFunctions = (is_welfare = false) => ({
    [REPORT_ROUTES.CONTACT]: {
        get: (userId: string, data: Params) => getContactReportListByUser(userId, data),
        columns: columnsContact,
    },
    [REPORT_ROUTES.REQUEST]: {
        get: (userId: string, data: Params) => getRequestReportListByUser(userId, { ...data, is_welfare }),
        columns: columnsContact,
    },
    [REPORT_ROUTES.COMMUNICATION_APP]: {
        get: (userId: string, data: Params) => getApplicationReportListByUser(userId, data),
        columns: columnsContact,
    },
});

const IPComReportList = (props: ReportPageProps) => {
    const { t, categoryType, is_welfare = false } = props;
    const functionGetData = getReportFunctions(is_welfare);
    const { user } = useAuthenStore();
    const navigate = useNavigate();

    const [page, setPage] = useState(1);

    const type = REPORT_ROUTES[categoryType];

    const { dataSource, total, isLoading } = useGetDataReport({
        page,
        userId: user?.id,
        callApi: (data: Params) => functionGetData[type].get(user.id, data),
    });

    const onEdit = (key: string) => {
        navigate(`${key}/edit`);
    };

    const onDetail = (key: string) => {
        navigate(`${key}`);
    };

    const onChangeTable: TableProps<ReportType>['onChange'] = (pagination) => {
        setPage(pagination.current);
    };

    return (
        <PageComponent
            title={<PageTitle routes={[{ path: '', breadcrumbName: t(`report.ip_${type}_title`) }]} className="py-6" />}
            isDownLoadCsv={false}
        >
            <TableComponent
                className="mt-4"
                loading={isLoading}
                columns={functionGetData[type].columns({ t, onEdit, onDetail })}
                dataSource={dataSource}
                onChange={onChangeTable}
                pagination={{
                    total: total,
                    current: page,
                }}
            />
        </PageComponent>
    );
};

export default withTrans(observer(IPComReportList));
