import { useTranslation } from 'react-i18next';
import FormInputTextarea from '@/components/common/form/FormInputTextarea';
import { SelectComponent } from '@/components/common/form/components/SelectComponent';
import { useGetPhrasesOptions } from '@/hooks/useGetPhrases';
import { SelectOptionsItem } from '@/interfaces/common/global';
import { useFormContext } from 'react-hook-form';

const ReasonDetail = ({ isWelfare = false }) => {
    const { t } = useTranslation();
    const { getValues, setValue, clearErrors } = useFormContext();
    const { options, onHandleSearch, setScrollOption, isLoading } = useGetPhrasesOptions({
        type: isWelfare ? 'welfare' : 'communication',
    });

    const onSelect = (value: SelectOptionsItem) => {
        const reason = getValues('content') || '';
        const reasonvalue = `${reason}${value.label as string}`;
        setValue('content', reasonvalue.slice(0, 255));
        clearErrors('content');
    };

    return (
        <div className=" flex flex-col justify-center">
            <SelectComponent
                isLoad={isLoading}
                handleSearch={onHandleSearch}
                setScrollOption={setScrollOption}
                label={t('application_accept.select_phrase')}
                options={options}
                onChangeSelect={onSelect}
                hasGetDataOptions={true}
                showValue={false}
                width="!w-[450px]"
            />
            <FormInputTextarea
                className="py-4 w-full"
                name="content"
                placeholder={t('report.placeholder_contact_report_content')}
                maxLength={255}
            />
            <div className="col-span-2 pl-4"></div>
        </div>
    );
};
export default ReasonDetail;
