import FormInputCheckboxGroup from '@/components/common/form/FormCheckboxGroup';
import FormInputCheckbox from '@/components/common/form/FormInputCheckbox';
import { SpinIcon } from '@/components/common/svg';
import { cn } from '@/lib/utils';
import { SelectedOption } from '@/pages/meeting/form/MeetingForm';
import { CheckedState } from '@radix-ui/react-checkbox';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

type SelectBoxProps = {
    isLoad?: boolean;
    sectionOptions?: any[];
    labelClass?: string;
    required?: boolean;
    handleSelectAll?: (label: string, checked: boolean | string) => void;
    onCheckboxChange?: (checked: CheckedState, field: Record<'id', string> & SelectedOption) => void;
};

const SelectBox = ({
    isLoad,
    sectionOptions,
    labelClass,
    handleSelectAll,
    onCheckboxChange,
    required,
}: SelectBoxProps) => {
    const { t } = useTranslation();
    const { setValue, getValues, watch, setError, clearErrors } = useFormContext();

    useEffect(() => {
        const subscription = watch((value, { name }) => {
            if (name?.includes('select.')) {
                const select = value.select;
                const isAll = select?.findIndex((item: any) => !item.checked) < 0;
                setValue(`selectAll_`, isAll);
            }
            if (name === 'child_category' && required) {
                const childCategory = value.child_category;
                if (childCategory?.length > 0) {
                    clearErrors('child_category');
                } else {
                    setError('child_category', { message: t('common.MSG_002', { field: t('category.department') }) });
                }
            }
        });

        // Cleanup the subscription on unmount.
        return () => subscription.unsubscribe();
    }, [watch]);

    const onHandleSelectAll = (name: string, checked: boolean | string) => {
        const options = (getValues(name) as SelectedOption[]) || [];
        setValue(
            name,
            options.map((item) => ({ ...item, checked: checked }))
        );
        handleSelectAll?.('', checked);
    };

    const onHandleCheckboxChange = (
        checked: CheckedState,
        field: Record<'id', string> & SelectedOption,
        label: string
    ) => {
        const isAll = getValues(`selectAll_${label}`);

        if (isAll) {
            setValue(`selectAll_${label}`, true);
            onCheckboxChange(checked, field);
            return;
        }

        const options = getValues(`select.${label}`) as SelectedOption[];
        const index = options?.findIndex((item) => !item.checked);
        setValue(`selectAll_${label}`, index < 0);
        onCheckboxChange?.(checked, field);
    };

    return (
        <div className="space-y-2 flex flex-wrap sm:flex-nowrap sm:gap-6 items-start">
            <div className={cn('inline-block min-w-fit', labelClass)} />
            <div
                className={`flex animate-accordion-up flex-col gap-y-4 my-4 px-4 py-2 shadow-lg shadow-slate-300 rounded-md w-full`}
            >
                {isLoad ? (
                    <span className="p-4 flex justify-center">
                        <SpinIcon className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-foreground" />
                    </span>
                ) : (
                    <>
                        {sectionOptions.map((section, index, arr) => {
                            const isLast = index === arr.length - 1;
                            return (
                                <section
                                    className={cn('flex flex-col gap-3 pb-6', {
                                        'border-b-2': !isLast,
                                        'border-b-slate-400': !isLast,
                                    })}
                                    key={section.label}
                                >
                                    <div className="flex items-center gap-x-6 pb-4">
                                        {section.label && <p className="inline-block text-[#ccc]">{section.label}</p>}
                                        {!!section.options.length && (
                                            <div className="inline-block">
                                                <FormInputCheckbox
                                                    classNameLabel="text-gray-600"
                                                    label={t('meeting.form_meeting.select_all')}
                                                    name={`selectAll_${section.label}`}
                                                    onCheckboxChange={(checked) =>
                                                        onHandleSelectAll(`select.${section.label}`, checked)
                                                    }
                                                />
                                            </div>
                                        )}
                                    </div>
                                    <FormInputCheckboxGroup
                                        options={section.options}
                                        onCheckboxChange={(
                                            checked: CheckedState,
                                            field: Record<'id', string> & SelectedOption
                                        ) => onHandleCheckboxChange(checked, field, section.label)}
                                        name={`select.${section.label}`}
                                        classNameLabel="max-w-[145px] max-h-[30px] inline-block whitespace-break-spaces ellipsis"
                                        containerClass="max-h-[210px] overflow-y-auto"
                                    />
                                </section>
                            );
                        })}
                    </>
                )}
            </div>
        </div>
    );
};

export default SelectBox;
