import withTrans from '@/components/common/hocs/withTrans';
import { CarouselComponent } from '@/components/common/pages/CarouseComponent';
import { Item, renderFooterCarouse, renderItemCarouse } from '@/components/common/pages/Element';
import GoogleMapView from '@/components/common/pages/GoogleMapView';
import PageComponent from '@/components/common/pages/PageComponent';
import PageTitle from '@/components/common/pages/PageTitle';
import ProgressDetail from '@/components/common/pages/ProgressDetail';
import { Separator } from '@/components/ui/separator';
import { Textarea } from '@/components/ui/textarea';
import { REPORT_ROUTES } from '@/constants/appConstants';
import { backLink } from '@/constants/path';
import { ReportDetailType, ReportPageProps } from '@/interfaces/report';
import { sortAttachments } from '@/lib/helper';
import { getReportApplicationDetail } from '@/modules/services/application.report.service';
import { getContactReportDetail } from '@/modules/services/contact.service';
import { getRequestReportDetail } from '@/modules/services/request.service';
import { useAuthenStore } from '@/store/auth/AuthStore';
import { useQuery } from '@tanstack/react-query';
import { observer } from 'mobx-react-lite';
import { useRef } from 'react';
import { useNavigate, useParams } from 'react-router';

const getReportFunctions = (is_wefare: boolean) => ({
    [REPORT_ROUTES.CONTACT]: {
        get: getContactReportDetail,
    },
    [REPORT_ROUTES.REQUEST]: {
        get: (id: string) => getRequestReportDetail(id, is_wefare),
    },
    [REPORT_ROUTES.COMMUNICATION_APP]: {
        get: getReportApplicationDetail,
    },
});

const IPComReportDetail = (props: ReportPageProps) => {
    const { t, categoryType, is_welfare = false } = props;
    const navigate = useNavigate();
    const functionData = getReportFunctions(is_welfare);
    const { user } = useAuthenStore();
    const { id } = useParams();
    const mapRef = useRef(null);
    const type = REPORT_ROUTES[categoryType];

    const getDetailReport = async (id: string) => {
        try {
            const res = await functionData[type].get(id);
            const data: ReportDetailType = res.data;
            mapRef.current.setCurrentLocation({ lat: data?.latitude, lng: data?.longitude });
            mapRef.current.setMarkers([{ lat: data?.latitude, lng: data?.longitude }]);
            return data;
        } catch (error) {
            return null;
        }
    };

    const { isFetching: isLoading, data } = useQuery({
        queryKey: ['getDetailReport', id],
        queryFn: () => {
            if (id) {
                return getDetailReport(id);
            }
        },
    });

    const attachments = sortAttachments(data?.attachments || []);
    const onBack = () => {
        navigate(
            is_welfare ? `/welfare/commissioner/${type}s` : `${backLink[user?.role?.communication?.[0]] || ''}/${type}s`
        );
    };

    return (
        <PageComponent
            pageLoading={isLoading}
            title={
                <PageTitle
                    className="py-6"
                    routes={[{ path: '', breadcrumbName: t(`report.ip_${type}_title_detail`) }]}
                />
            }
            isDownLoadCsv={false}
            textBack={t('go_back')}
            onBack={onBack}
        >
            <ProgressDetail
                titleColumns="report.time"
                status={data?.status}
                priority={data?.priority}
                isShowPriority={false}
                isLoading={isLoading}
                progress={data?.progresses?.map((item) => ({ ...item, key: item.id }))}
            />
            <Separator className="my-8 bg-grey" />
            <div className="flex flex-col gap-6">
                <Item label={t('category.parent_category')} data={data?.category?.content} className="items-center" />
                {data?.category_child && (
                    <Item
                        label={t('report.category_medium')}
                        data={data?.category_child?.content}
                        className="items-center"
                    />
                )}
                <Item label={t(`report.${type}_reason`)}>
                    <Textarea value={data?.content} readOnly={true} rows={5} className="text-pc disabled:cursor-text" />
                </Item>
                <Item label={t('report.image')} className="lg:col-span-3 ">
                    <CarouselComponent
                        wrapClass="w-[600px]"
                        carouselClass="w-[85%]"
                        data={attachments}
                        isShowNext={attachments.length > 1}
                        isShowPrevious={attachments.length > 1}
                        renderFooter={renderFooterCarouse}
                        renderItem={(item) => renderItemCarouse(item)}
                    />
                </Item>
                <Item label={t('report.address')} labelClass="mt-2">
                    <div className="w-full">
                        {data?.address && (
                            <div className=" px-2 py-2 border border-grey rounded-md overflow-hidden text-ellipsis">
                                {data?.address}
                            </div>
                        )}
                        <GoogleMapView
                            ref={mapRef}
                            multipleMaker={false}
                            loadCurrent={false}
                            zoom={16}
                            height={'300px'}
                            className="justify-normal mt-4"
                            isView={true}
                            defaultPos={null}
                        />
                    </div>
                </Item>
            </div>
        </PageComponent>
    );
};

export default withTrans(observer(IPComReportDetail));
