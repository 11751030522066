import PageComponent from '@/components/common/pages/PageComponent';
import PageTitle from '@/components/common/pages/PageTitle';
import TableBar from '@/components/common/table/TableBar';
import TableComponent from '@/components/common/table/TableComponent';
import { columns, mediumTableColumn } from '@/pages/categories/components/Columns';
import withTrans, { WithTransProps } from '@/components/common/hocs/withTrans';
import { observer } from 'mobx-react-lite';
import { Navigate, useNavigate, useParams } from 'react-router';
import useGetCategories from '@/hooks/useGetCategories';
import { useAuthenStore } from '@/store/auth/AuthStore';
import { TableProps } from 'antd';
import { CATEGORY_TABS, Category, CategoryParam, CategoryType } from '@/interfaces/category';
import { toCategoryType } from '@/lib/utils';
import { showModalConfirm } from '@/components/common/pages/ModalConfirm';
import { useGlobalStore } from '@/store/global/GlobalStore';
import { CategoryService } from '@/modules/services';
import { toast } from '@/components/ui/use-toast';
import { useCsvStore } from '@/store/csv/CsvStore';
import { convertFileNameCsv, handleErrorMessage, handleErrorNotFound } from '@/lib/helper';
import { createFileCategoryChildCsv, createFileCategoryCsv } from '@/modules/services/category.service';
import useRefreshData from '@/hooks/useRefreshData';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { useSearchParams } from 'react-router-dom';
import { PropsWithWelfare } from '@/interfaces/common/global';

export const CategoryList = (props: WithTransProps & PropsWithWelfare) => {
    const { t, is_welfare = false } = props;
    const { user } = useAuthenStore();
    const navigate = useNavigate();
    const { addQueue } = useCsvStore();
    const { type } = useParams<{ type: CategoryParam }>();
    const { setLoading } = useGlobalStore();
    const [searchParams, setSearchParams] = useSearchParams();
    const categoryType = toCategoryType(type) as Category;
    const activeTabParams =
        searchParams.get('type') === CATEGORY_TABS.CHILD ? CATEGORY_TABS.CHILD : CATEGORY_TABS.PARENT;

    const { refreshDataList, page, setPage, queryClient } = useRefreshData({ queryKey: ['getCategoryList'] });

    const { dataSource, total, isLoading, isDownLoadCsv } = useGetCategories({
        type: activeTabParams,
        page,
        userId: user?.id,
        categoryType,
        orderBy: 'created_at desc',
        isWelfare: is_welfare,
    });

    const onEdit = (key: string) => {
        navigate(`${key}?type=${activeTabParams}`);
    };

    const onChangeActiveTab = (key: CATEGORY_TABS) => {
        if (activeTabParams !== key) {
            setSearchParams({ type: key });
            setPage(1);
            queryClient?.refetchQueries({ queryKey: ['getCategoryList', 1, categoryType, key, is_welfare] });
        }
    };

    const onDelete = async (key: string) => {
        if (!user.id) return;

        try {
            const res = await showModalConfirm({
                title: t('delete'),
                message: t('common.MSG_007'),
                type: 'delete',
            });
            if (res) {
                setLoading(true);
                activeTabParams === CATEGORY_TABS.PARENT
                    ? await CategoryService.deleteCategory(key)
                    : await CategoryService.deleteCategoryChild(key);

                refreshDataList([categoryType, activeTabParams, is_welfare]);
                toast({ description: t('common.MSG_016'), variant: 'success' });
            }
        } catch (error) {
            const isNotFound = handleErrorNotFound({
                error,
                message: 'common.MSG_023',
                callback: () => refreshDataList([categoryType, activeTabParams, is_welfare]),
            });

            if (isNotFound) return;

            handleErrorMessage({
                error: error?.errors?.category?.[0] || '',
                callback: (msg) => msg.includes('MSG_063') && refreshDataList([categoryType, activeTabParams]),
                defaultMessage: 'common.MSG_041',
            });
        } finally {
            setLoading(false);
        }
    };

    const onChangeTable: TableProps<CategoryType>['onChange'] = (pagination) => {
        setPage(pagination.current);
    };

    const onDownloadCsv = async () => {
        try {
            const newType = type === 'communication-app' ? 'application' : type;
            activeTabParams === CATEGORY_TABS.PARENT
                ? await createFileCategoryCsv(newType, is_welfare)
                : await createFileCategoryChildCsv(newType, is_welfare);

            addQueue({
                flag: activeTabParams === CATEGORY_TABS.PARENT ? `category_${type}` : `category_child_${type}`,
                status: 'pending',
                fileName: convertFileNameCsv({
                    prefixName: t(
                        activeTabParams === CATEGORY_TABS.PARENT
                            ? `category.parent_${type}_list`
                            : `category.child_${type}_list`
                    ),
                }),
                url:
                    activeTabParams === CATEGORY_TABS.PARENT
                        ? `users/export_csv_category_${newType}`
                        : `users/export_csv_category_child_${newType}`,
            });
            toast({ description: t('common.MSG_045'), variant: 'success' });
        } catch (error) {
            toast({ description: t('common.MSG_040'), variant: 'destructive' });
        }
    };

    if (!['contact', 'request', 'communication-app', 'application'].includes(type)) {
        return <Navigate to="/not-found" />;
    }

    return (
        <Tabs value={activeTabParams}>
            <div className="w-full lg:px-2 xl:px-16 md:pt-6 sm:pt-6">
                <TabsList className="w-full flex h-[50px]">
                    <TabsTrigger
                        onClick={() => onChangeActiveTab(CATEGORY_TABS.PARENT)}
                        className="w-full h-full text-base text"
                        value={CATEGORY_TABS.PARENT}
                    >
                        {t('category.parent_category')}
                    </TabsTrigger>
                    <TabsTrigger
                        onClick={() => onChangeActiveTab(CATEGORY_TABS.CHILD)}
                        className="w-full h-full text-base text"
                        value={CATEGORY_TABS.CHILD}
                    >
                        {t('category.child_category')}
                    </TabsTrigger>
                </TabsList>
            </div>
            <TabsContent value={CATEGORY_TABS.PARENT}>
                <PageComponent
                    title={<PageTitle routes={[{ path: '', breadcrumbName: t(`category.parent_${type}_list`) }]} />}
                    isDownLoadCsv={isDownLoadCsv}
                    onDownLoadCsv={onDownloadCsv}
                >
                    <TableBar
                        hasSelect={false}
                        isCreate
                        onCreate={() => navigate('create', { state: { activeTab: activeTabParams } })}
                        textCreate={t(`category.create_${activeTabParams}_${type}`)}
                    />
                    <TableComponent
                        loading={isLoading}
                        columns={columns({ t, onEdit, onDelete, type: categoryType })}
                        dataSource={dataSource}
                        pagination={{
                            total: total,
                            current: page,
                        }}
                        onChange={onChangeTable}
                    />
                </PageComponent>
            </TabsContent>
            <TabsContent value={CATEGORY_TABS.CHILD}>
                <PageComponent
                    title={<PageTitle routes={[{ path: '', breadcrumbName: t(`category.child_${type}_list`) }]} />}
                    isDownLoadCsv={isDownLoadCsv}
                    onDownLoadCsv={onDownloadCsv}
                >
                    <TableBar
                        hasSelect={false}
                        isCreate
                        onCreate={() => navigate(`create?type=${activeTabParams}`)}
                        textCreate={t(`category.create_${activeTabParams}_${type}`)}
                    />
                    <TableComponent
                        loading={isLoading}
                        columns={mediumTableColumn({ t, onEdit, onDelete, type: categoryType })}
                        dataSource={dataSource}
                        pagination={{
                            total: total,
                            current: page,
                        }}
                        onChange={onChangeTable}
                    />
                </PageComponent>
            </TabsContent>
        </Tabs>
    );
};
export default withTrans(observer(CategoryList));
