import { CategoryService } from '@/modules/services';
import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';

export enum CateEnum {
    CONTACT = 'Contact',
    COMMUNICATION_APP = 'CommunicationApp',
    REQUEST = 'Request',
}
export type CateType = 'Contact' | 'CommunicationApp' | 'Request';
type ReponseCategory = {
    data: Category[];
    meta: number;
    isLoading?: boolean;
};
interface CategoryResponse {
    id: string;
    type: CateType;
    content: string;
}
export type Category = Pick<CategoryResponse, 'id' | 'content'>;

const useGetComCategories = ({
    type,
    orderBy,
    page = 1,
    isWelfare = false,
}: {
    type: CateType;
    orderBy?: string;
    page: number;
    isWelfare?: boolean;
}): ReponseCategory => {
    const [currentData, setCurrentData] = useState<Category[]>([]);
    const [total, setTotal] = useState<number>(0);

    const getCategories = async () => {
        try {
            const { data, meta }: any = await CategoryService.getCategory({
                page: page,
                type: type,
                order_by: orderBy,
                per_page: 20,
                is_welfare: isWelfare,
            });
            const newResponse = data.map((cate: CategoryResponse) => ({
                id: cate.id,
                content: cate.content,
            })) as Category[];
            setCurrentData((prev) => [...prev, ...newResponse]);
            setTotal(meta.total_count ?? 0);
            return true;
        } catch (error) {
            setCurrentData([]);
            setTotal(0);
            return false;
        }
    };

    const { isFetching: isLoading } = useQuery({
        queryKey: ['getCategories', page, isWelfare],
        queryFn: () => getCategories(),
    });
    return { data: currentData, meta: total, isLoading };
};

export const useGetComCategoriesChild = ({
    type,
    orderBy,
    page = 1,
    categoryId,
    isWelfare = false,
}: {
    type: CateType;
    orderBy?: string;
    page: number;
    categoryId: string;
    isWelfare?: boolean;
}): ReponseCategory => {
    const [currentData, setCurrentData] = useState<Category[]>([]);
    const [total, setTotal] = useState<number>(0);

    const getCategories = async () => {
        try {
            if (!categoryId) return;
            const { data, meta }: any = await CategoryService.getChildrenCategoryList({
                page: page,
                type: type,
                order_by: orderBy,
                per_page: 20,
                category_id: categoryId,
                is_welfare: isWelfare,
            });
            const newResponse = data.map((cate: CategoryResponse) => ({
                id: cate.id,
                content: cate.content,
            })) as Category[];
            setCurrentData((prev) => [...prev, ...newResponse]);
            setTotal(meta.total_count ?? 0);
            return true;
        } catch (error) {
            setCurrentData([]);
            setTotal(0);
            return false;
        }
    };

    const { isFetching: isLoading } = useQuery({
        queryKey: ['getCategoriesChild', page, categoryId, isWelfare],
        queryFn: () => getCategories(),
    });
    return { data: currentData, meta: total, isLoading };
};

export default useGetComCategories;
