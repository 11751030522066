import { apiServiceWithAuth } from '@/modules/http/apiServiceWithAuth';
import { Category, CategoryType } from '@/interfaces/category';
import { Params, PhrasesComponentType } from '@/interfaces/common/global';
import { PhrasesReq } from '@/interfaces/phrases';

export const getCategory = async (
    params: Params & { order_by?: string; content?: string; type: Category; is_welfare?: boolean }
) => {
    return await apiServiceWithAuth.get(`categories`, { params });
};

export const createCategory = async (body: CategoryType) => {
    return await apiServiceWithAuth.post(`categories`, { category: body });
};

export const getDetailCategory = async (id: string) => {
    return await apiServiceWithAuth.get(`categories/${id}`);
};

export const editCategory = async (id: string, body: CategoryType) => {
    return await apiServiceWithAuth.patch(`categories/${id}`, { category: body });
};

export const deleteCategory = async (id: string) => {
    return await apiServiceWithAuth.delete(`categories/${id}`);
};

export const createFileCategoryCsv = async (type: string, is_welfare?: boolean) => {
    return await apiServiceWithAuth.post(`users/export_csv_category_${type}`, { is_welfare });
};

export const getListPhrases = async (type: PhrasesComponentType, params?: Params & { content?: string }) => {
    return apiServiceWithAuth.get(`${type}_sample_texts`, { params });
};

export const createPhrases = async (type: PhrasesComponentType, params: PhrasesReq) => {
    return apiServiceWithAuth.post(`${type}_sample_texts`, params);
};

export const deletePhrases = async (type: PhrasesComponentType, id: string) => {
    return await apiServiceWithAuth.delete(`${type}_sample_texts/${id}`);
};

export const updatePhrases = async (type: PhrasesComponentType, id: string, params: PhrasesReq) => {
    return await apiServiceWithAuth.put(`${type}_sample_texts/${id}`, params);
};

export const getDetailPhrases = async (type: PhrasesComponentType, id: string) => {
    return await apiServiceWithAuth.get(`${type}_sample_texts/${id}`);
};
export const GetCategoryApplicationList = async (params?: Params & { survey_type?: string }) => {
    return await apiServiceWithAuth.get(`users/surveys`, { params });
};
export const GetDetailCategoryApplication = async (surveyId: string) => {
    return await apiServiceWithAuth.get(`users/surveys/${surveyId}`);
};
export const DeleteCategoryApplication = async (surveyId: string) => {
    return await apiServiceWithAuth.delete(`users/surveys/${surveyId}`);
};
export const UpdateCategoryApplication = async (surveyId: string, data: any) => {
    return await apiServiceWithAuth.put(`users/surveys/${surveyId}`, data);
};

export const getChildrenCategoryList = async (
    params: Params & {
        order_by?: string;
        content?: string;
        type: Category;
        category_id?: string;
        is_by_user?: boolean;
        is_welfare?: boolean;
    }
) => {
    return await apiServiceWithAuth.get(`category_children`, { params });
};

export const createCategoryChild = async (body: CategoryType) => {
    return await apiServiceWithAuth.post(`category_children`, { category_child: body });
};

export const getDetailCategoryChild = async (id: string) => {
    return await apiServiceWithAuth.get(`category_children/${id}`);
};

export const editCategoryChild = async (id: string, body: CategoryType) => {
    return await apiServiceWithAuth.patch(`category_children/${id}`, { category_child: body });
};

export const createFileCategoryChildCsv = async (type: string, is_welfare?: boolean) => {
    return await apiServiceWithAuth.post(`users/export_csv_category_child_${type}`, { is_welfare });
};

export const deleteCategoryChild = async (id: string) => {
    return await apiServiceWithAuth.delete(`category_children/${id}`);
};
