import { Button } from '@/components/ui/button';
import { FormField, FormMessage } from '@/components/ui/form';
import { LoadingComponent } from '@/components/ui/loading';
import { CateType, useGetComCategoriesChild } from '@/hooks/useGetCommunicationCategory';
import { cn } from '@/lib/utils';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

interface Props {
    pageType: string;
    isWelfare?: boolean;
}

const SelectedChildCategory = (props: Props) => {
    const { t } = useTranslation();
    const { pageType, isWelfare } = props;
    const lastItemRef = useRef<HTMLDivElement>(null);
    const [page, setPage] = useState<number>(1);
    const {
        formState: {
            errors: { category_child_id: category },
        },
        clearErrors,
        setValue,
        watch,
        control,
    } = useFormContext();
    const selectedCate = watch('category_child_id');
    const selectedParent = watch('category_id');

    // integrate API

    const { data, meta, isLoading } = useGetComCategoriesChild({
        page,
        type: pageType as CateType,
        categoryId: selectedParent,
        isWelfare,
    });

    const onScroll = useCallback(() => {
        const newPage = page + 1;
        const pageNum = Math.ceil(meta / 20);
        if (newPage <= pageNum) {
            setPage(newPage);
        }
    }, [page, meta]);

    useEffect(() => {
        if (lastItemRef.current && data.length) {
            const observer = new IntersectionObserver(
                (entry) => {
                    if (entry[0].isIntersecting) {
                        onScroll();
                    }
                },
                {
                    threshold: 1.0,
                }
            );
            observer.observe(lastItemRef.current);
            return () => {
                if (lastItemRef.current) {
                    observer.unobserve(lastItemRef.current);
                }
            };
        }
    }, [lastItemRef, data.length, onScroll]);

    return (
        <div>
            <div
                className={`flex justify-start items-center w-full flex-wrap overflow-y-auto `}
                style={{
                    maxHeight: 'calc(100vh - 420px)',
                }}
            >
                {data.map(({ content, id }, i) => (
                    <div key={i} className="w-full sm:w-[50%] px-[16px] py-[16px]">
                        <Button
                            variant="outline"
                            size="xxl"
                            className={cn(`
                                w-full h-[78px] text-pc whitespace-break-spaces
                                ${category?.message ? '!border-destructive' : ''}
                                ${selectedCate === id ? '!bg-[#84cc16]' : 'hover:!bg-transparent'}
                            `)}
                            onClick={() => {
                                setValue('category_child_id', selectedCate === id ? '' : id);
                                clearErrors('category_child_id');
                            }}
                            children={content}
                        />
                    </div>
                ))}
                {!data.length && (
                    <span className="py-[100px] w-full text-center text-pc">
                        {t('application_register.empty_category')}
                    </span>
                )}
                <div ref={lastItemRef}></div>
            </div>
            <div className={`flex items-center justify-start w-full`}>
                <FormField
                    name={'category_child_id'}
                    control={control}
                    render={() => <FormMessage className="text-error w-[500px] px-3" />}
                />
            </div>
            {isLoading && <LoadingComponent />}
        </div>
    );
};
export default SelectedChildCategory;
