import { lazy } from 'react';
import { APP_PERMISSION } from '@/constants/permissionConfig';
import { CATEGORIES, REPORT_ROUTES } from '@/constants/appConstants';
import { CategoriesItem } from '@/interfaces/common/global';
import CommissionerRequestRegister from '@/pages/ipad-reports/CommissionerRequestRegister';

const AccountList = lazy(() => import('@/pages/account'));
const CreateAccount = lazy(() => import('@/pages/account/components/CreateAccount'));
const UpdateAccount = lazy(() => import('@/pages/account/components/UpdateAccount'));
const IPSurveyList = lazy(() => import('@/pages/survey/ipad/IPSurveyList'));
const IPSurveyQuestion = lazy(() => import('@/pages/survey/ipad/components/IPSurveyQuestion'));
const ResidentList = lazy(() => import('@/pages/resident'));
const DashboardMenu = lazy(() => import('@/pages/dashboad-menu/DashboardMenu'));
const VisitReport = lazy(() => import('@/pages/visit-report'));
const VisitReportDetail = lazy(() => import('@/pages/visit-report/VisitReportDetail'));
const SurveyList = lazy(() => import('@/pages/survey'));
const SurveyDetail = lazy(() => import('@/pages/survey/SurveyDetail'));
const ResidentDetail = lazy(() => import('@/pages/resident/EditResident'));
const CreateResident = lazy(() => import('@/pages/resident/CreateResident'));
const QuestionList = lazy(() => import('@/pages/survey/question/QuestionList'));
const AddQuestion = lazy(() => import('@/pages/survey/question/AddQuestion'));
const EditQuestion = lazy(() => import('@/pages/survey/question/EditQuestion'));
const ApplicationList = lazy(() => import('@/pages/application'));
const ApplicationDetail = lazy(() => import('@/pages/application/ApplicationDetail'));
const PhrasesComponent = lazy(() => import('@/pages/phrases'));
const CreatePhrases = lazy(() => import('@/pages/phrases/components/Create'));
const EditPhrases = lazy(() => import('@/pages/phrases/components/Edit'));
const IPResidentList = lazy(() => import('@/pages/resident/ipad/IPResidentList'));
const IPResidentReportList = lazy(() => import('@/pages/visit-report/ipad/IPResidentReportList'));
const IPCreateResidentReport = lazy(() => import('@/pages/visit-report/ipad/IPCreateResidentReport'));
const IPApplicationResidentList = lazy(() => import('@/pages/application/ipad/IPApplicationResidentList'));
const IPApplicationDetail = lazy(() => import('@/pages/application/ipad/IPApplicationDetail'));
const IPRequestApp = lazy(() => import('@/pages/application/ipad/IPRequestApp'));
const IPRequestAppDetail = lazy(() => import('@/pages/application/ipad/IPRequestAppDetail'));
const Documents = lazy(() => import('@/pages/document'));
const IPCreateResident = lazy(() => import('@/pages/resident/ipad/IPCreateResident'));
const IPEditResident = lazy(() => import('@/pages/resident/ipad/IPEditResident'));
const IPDetailResident = lazy(() => import('@/pages/resident/ipad/IPDetailResident'));
const CategoryList = lazy(() => import('@/pages/categories/index'));
const CreateCategory = lazy(() => import('@/pages/categories/components/Create'));
const CategoryApplicationList = lazy(() => import('@/pages/categories/application'));
const CategoryApplicationDetail = lazy(() => import('@/pages/categories/application/components/Detail'));
const CreateCategoryApplication = lazy(() => import('@/pages/categories/application/components/Create'));
const EditCategoryApplication = lazy(() => import('@/pages/categories/application/components/Edit'));
const ContactRegister = lazy(() => import('@/pages/communication/ContactRegister'));
const ApplicationRegister = lazy(() => import('@/pages/communication/ApplicationRegister'));
const RequestRegister = lazy(() => import('@/pages/communication/RequestRegister'));
const EditCategory = lazy(() => import('@/pages/categories/components/Edit'));
const ReportList = lazy(() => import('@/pages/reports'));
const CreateReport = lazy(() => import('@/pages/reports/components/CreateReport'));
const EditReport = lazy(() => import('@/pages/reports/components/EditReport'));
const ReportDetail = lazy(() => import('@/pages/reports/components/ReportDetail'));
const ReportApplicationList = lazy(() => import('@/pages/reports/application'));
const ReportApplicationDetail = lazy(() => import('@/pages/reports/application/components/ApplicationDetail'));
const IPComReportList = lazy(() => import('@/pages/ipad-reports/IPComReportList'));
const IPComReportDetail = lazy(() => import('@/pages/ipad-reports/IPComReportDetail'));
const MeetingList = lazy(() => import('@/pages/meeting'));
const CreateMeeting = lazy(() => import('@/pages/meeting/components/CreateMeeting'));
const UpdateMeeting = lazy(() => import('@/pages/meeting/components/EditMeeting'));
const DetailMeeting = lazy(() => import('@/pages/meeting/components/DetailMeeting'));
const QuestionDetail = lazy(() => import('@/pages/survey/question/QuestionDetail'));
const IPMeetingAttendanceList = lazy(() => import('@/pages/ipad-meeting/IPMeetingAttendanceList'));
const IPAttendanceDetail = lazy(() => import('@/pages/ipad-meeting/IPAttendanceDetail'));
const IPChangeAttendance = lazy(() => import('@/pages/ipad-meeting/IPChangeAttendance'));
const RepresentativeRequest = lazy(() => import('@/pages/communication/RepresentativeRequest'));
const RepresentativeApplication = lazy(() => import('@/pages/communication/RepresentativeApplication'));
const IPComApplicationList = lazy(() => import('@/pages/ipad-reports/IPComApplication/IPComApplicationList'));
const IPComApplicationQuestion = lazy(
    () => import('@/pages/ipad-reports/IPComApplication/components/IPComApplicationQuestion')
);

export const communicationRoutes = [
    {
        path: '',
        element: <DashboardMenu module="communication" />,
        roles: [APP_PERMISSION.LOCAl_GOV_COMMUNICATION, APP_PERMISSION.MAYOR, APP_PERMISSION.REPRESENTATIVE],
    },
    {
        path: 'categories/application',
        element: <CategoryApplicationList />,
        roles: [APP_PERMISSION.LOCAl_GOV_COMMUNICATION],
    },
    {
        path: 'categories/application/create',
        element: <CreateCategoryApplication />,
        roles: [APP_PERMISSION.LOCAl_GOV_COMMUNICATION],
    },
    {
        path: 'categories/application/:id',
        element: <CategoryApplicationDetail />,
        roles: [APP_PERMISSION.LOCAl_GOV_COMMUNICATION],
    },
    {
        path: 'categories/application/:id/edit/',
        element: <EditCategoryApplication />,
        roles: [APP_PERMISSION.LOCAl_GOV_COMMUNICATION],
    },
    {
        path: 'categories/:type',
        element: <CategoryList />,
        roles: [APP_PERMISSION.LOCAl_GOV_COMMUNICATION],
        type: ['contact'],
    },
    {
        path: 'categories/:type/create',
        element: <CreateCategory />,
        roles: [APP_PERMISSION.LOCAl_GOV_COMMUNICATION],
    },
    {
        path: 'categories/:type/:id',
        element: <EditCategory />,
        roles: [APP_PERMISSION.LOCAl_GOV_COMMUNICATION],
    },
    {
        path: 'reports/application',
        element: <ReportApplicationList />,
        roles: [APP_PERMISSION.LOCAl_GOV_COMMUNICATION],
    },
    {
        path: 'reports/application/:id',
        element: <ReportApplicationDetail type="application" />,
        roles: [APP_PERMISSION.LOCAl_GOV_COMMUNICATION],
    },
    ...Object.keys(CATEGORIES).map((item: CategoriesItem) => ({
        path: `reports/${REPORT_ROUTES[item]}`,
        element: <ReportList categoryType={item} />,
        roles: [APP_PERMISSION.LOCAl_GOV_COMMUNICATION],
    })),
    ...Object.keys(CATEGORIES).map((item: CategoriesItem) => ({
        path: `reports/${REPORT_ROUTES[item]}/create`,
        element: <CreateReport categoryType={item} />,
        roles: [APP_PERMISSION.LOCAl_GOV_COMMUNICATION],
    })),
    ...Object.keys(CATEGORIES).map((item: CategoriesItem) => ({
        path: `reports/${REPORT_ROUTES[item]}/:id`,
        element: <ReportDetail categoryType={item} />,
        roles: [APP_PERMISSION.LOCAl_GOV_COMMUNICATION],
    })),
    ...Object.keys(CATEGORIES).map((item: CategoriesItem) => ({
        path: `reports/${REPORT_ROUTES[item]}/:id/edit`,
        element: <EditReport categoryType={item} />,
        roles: [APP_PERMISSION.LOCAl_GOV_COMMUNICATION],
    })),
    {
        path: 'phrases',
        element: <PhrasesComponent type="communication" />,
        roles: [APP_PERMISSION.LOCAl_GOV_COMMUNICATION],
    },
    {
        path: 'phrases/create',
        element: <CreatePhrases type="communication" />,
        roles: [APP_PERMISSION.LOCAl_GOV_COMMUNICATION],
    },
    {
        path: 'phrases/:id',
        element: <EditPhrases type="communication" />,
        roles: [APP_PERMISSION.LOCAl_GOV_COMMUNICATION],
    },
    {
        path: 'documents',
        element: <Documents type="communication" />,
        roles: [APP_PERMISSION.LOCAl_GOV_COMMUNICATION],
    },
    {
        path: 'meeting',
        element: <MeetingList />,
        roles: [APP_PERMISSION.LOCAl_GOV_COMMUNICATION],
    },
    {
        path: 'meeting/create',
        element: <CreateMeeting />,
        roles: [APP_PERMISSION.LOCAl_GOV_COMMUNICATION],
    },
    {
        path: 'meeting/update/:id',
        element: <UpdateMeeting />,
        roles: [APP_PERMISSION.LOCAl_GOV_COMMUNICATION],
    },
    {
        path: 'meeting/detail/:id',
        element: <DetailMeeting />,
        roles: [APP_PERMISSION.LOCAl_GOV_COMMUNICATION],
    },
];

export const welfareRoutes = [
    {
        path: '',
        element: <DashboardMenu module="welfare" />,
        roles: [APP_PERMISSION.LOCAL_GOV_WELFARE, APP_PERMISSION.COMMISSIONER],
    },
    {
        path: 'visit-reports',
        element: <VisitReport />,
        roles: [APP_PERMISSION.LOCAL_GOV_WELFARE],
    },
    {
        path: 'visit-reports/:id',
        element: <VisitReportDetail />,
        roles: [APP_PERMISSION.LOCAL_GOV_WELFARE],
    },
    {
        path: `reports-request`,
        element: <ReportList categoryType="REQUEST" is_welfare={true} />,
        roles: [APP_PERMISSION.LOCAL_GOV_WELFARE],
    },
    {
        path: `reports-request/create`,
        element: <CreateReport categoryType="REQUEST" is_welfare={true} />,
        roles: [APP_PERMISSION.LOCAL_GOV_WELFARE],
    },
    {
        path: `reports-request/:id`,
        element: <ReportDetail categoryType="REQUEST" is_welfare={true} />,
        roles: [APP_PERMISSION.LOCAL_GOV_WELFARE],
    },
    {
        path: `reports-request/:id/edit`,
        element: <EditReport categoryType="REQUEST" is_welfare={true} />,
        roles: [APP_PERMISSION.LOCAL_GOV_WELFARE],
    },
    {
        path: 'categories/:type',
        element: <CategoryList is_welfare={true} />,
        roles: [APP_PERMISSION.LOCAL_GOV_WELFARE],
        type: ['contact'],
    },
    {
        path: 'categories/:type/create',
        element: <CreateCategory is_welfare={true} />,
        roles: [APP_PERMISSION.LOCAL_GOV_WELFARE],
    },
    {
        path: 'categories/:type/:id',
        element: <EditCategory is_welfare={true} />,
        roles: [APP_PERMISSION.LOCAL_GOV_WELFARE],
    },
    {
        path: 'surveys',
        element: <SurveyList />,
        roles: [APP_PERMISSION.LOCAL_GOV_WELFARE],
    },
    {
        path: 'surveys/:id',
        element: <SurveyDetail type="general" />,
        roles: [APP_PERMISSION.LOCAL_GOV_WELFARE],
    },
    {
        path: 'surveys/survey_61/:id',
        element: <SurveyDetail type="survey_61" />,
        roles: [APP_PERMISSION.LOCAL_GOV_WELFARE],
    },
    {
        path: 'questions',
        element: <QuestionList />,
        roles: [APP_PERMISSION.LOCAL_GOV_WELFARE],
    },
    {
        path: 'questions/create',
        element: <AddQuestion />,
        roles: [APP_PERMISSION.LOCAL_GOV_WELFARE],
    },
    {
        path: 'questions/:id',
        element: <QuestionDetail />,
        roles: [APP_PERMISSION.LOCAL_GOV_WELFARE],
    },
    {
        path: 'questions/:id/edit',
        element: <EditQuestion />,
        roles: [APP_PERMISSION.LOCAL_GOV_WELFARE],
    },
    {
        path: 'residents',
        element: <ResidentList />,
        roles: [APP_PERMISSION.LOCAL_GOV_WELFARE],
    },
    {
        path: 'residents/:id',
        element: <ResidentDetail />,
        roles: [APP_PERMISSION.LOCAL_GOV_WELFARE],
    },
    {
        path: 'residents/create',
        element: <CreateResident />,
        roles: [APP_PERMISSION.LOCAL_GOV_WELFARE],
    },
    {
        path: 'applications',
        element: <ApplicationList />,
        roles: [APP_PERMISSION.LOCAL_GOV_WELFARE],
    },
    {
        path: 'applications/:id',
        element: <ApplicationDetail />,
        roles: [APP_PERMISSION.LOCAL_GOV_WELFARE],
    },
    {
        path: 'phrases',
        element: <PhrasesComponent type="welfare" />,
        roles: [APP_PERMISSION.LOCAL_GOV_WELFARE],
    },
    {
        path: 'phrases/create',
        element: <CreatePhrases type="welfare" />,
        roles: [APP_PERMISSION.LOCAL_GOV_WELFARE],
    },
    {
        path: 'phrases/:id',
        element: <EditPhrases type="welfare" />,
        roles: [APP_PERMISSION.LOCAL_GOV_WELFARE],
    },
    {
        path: 'documents',
        element: <Documents type="welfare" />,
        roles: [APP_PERMISSION.LOCAL_GOV_WELFARE],
    },
];

export const commissionerRoutes = [
    {
        path: 'commissioner/visit-reports',
        element: <IPResidentReportList />,
        roles: [APP_PERMISSION.COMMISSIONER],
    },
    {
        path: 'commissioner/visit-reports/:id',
        element: <IPCreateResidentReport />,
        roles: [APP_PERMISSION.COMMISSIONER],
    },
    {
        path: 'commissioner/residents',
        element: <IPResidentList />,
        roles: [APP_PERMISSION.COMMISSIONER],
    },
    {
        path: 'commissioner/residents/:id',
        element: <IPEditResident />,
        roles: [APP_PERMISSION.COMMISSIONER],
    },
    {
        path: 'commissioner/applications',
        element: <IPApplicationResidentList />,
        roles: [APP_PERMISSION.COMMISSIONER],
    },
    {
        path: 'commissioner/applications/:id',
        element: <IPApplicationDetail />,
        roles: [APP_PERMISSION.COMMISSIONER],
    },
    {
        path: 'commissioner/applications/:residentId/:id',
        element: <IPRequestAppDetail />,
        roles: [APP_PERMISSION.COMMISSIONER],
    },
    {
        path: 'commissioner/applications/:residentId/create',
        element: <IPRequestApp type="create" />,
        roles: [APP_PERMISSION.COMMISSIONER],
    },
    {
        path: 'commissioner/applications/:residentId/edit/:id',
        element: <IPRequestApp type="edit" />,
        roles: [APP_PERMISSION.COMMISSIONER],
    },
    {
        path: 'commissioner/documents',
        element: <Documents type="commissioner" />,
        roles: [APP_PERMISSION.COMMISSIONER],
    },
    {
        path: 'commissioner/residents/create',
        element: <IPCreateResident />,
        roles: [APP_PERMISSION.COMMISSIONER],
    },
    {
        path: 'commissioner/surveys',
        element: <IPSurveyList />,
        roles: [APP_PERMISSION.COMMISSIONER],
    },
    {
        path: 'commissioner/surveys/:id',
        element: <IPSurveyQuestion />,
        roles: [APP_PERMISSION.COMMISSIONER],
    },
    {
        path: 'commissioner/residents/detail/:id',
        element: <IPDetailResident />,
        roles: [APP_PERMISSION.COMMISSIONER],
    },
    {
        path: 'commissioner/register-requests',
        element: <CommissionerRequestRegister />,
        roles: [APP_PERMISSION.COMMISSIONER],
    },
    {
        path: 'commissioner/requests/:id/edit',
        element: <CommissionerRequestRegister />,
        roles: [APP_PERMISSION.COMMISSIONER],
    },
    {
        path: 'commissioner/requests',
        element: <IPComReportList categoryType="REQUEST" is_welfare={true} />,
        roles: [APP_PERMISSION.COMMISSIONER],
    },
    {
        path: 'commissioner/requests/:id',
        element: <IPComReportDetail categoryType="REQUEST" is_welfare={true} />,
        roles: [APP_PERMISSION.COMMISSIONER],
    },
];

export const mayorRoutes = [
    {
        path: 'mayor/visit-reports',
        element: <VisitReport />,
        roles: [APP_PERMISSION.MAYOR],
    },
    {
        path: 'mayor/register-contact',
        element: <ContactRegister />,
        roles: [APP_PERMISSION.MAYOR],
    },
    {
        path: 'mayor/contacts/:id/edit',
        element: <ContactRegister />,
        roles: [APP_PERMISSION.MAYOR],
    },
    {
        path: 'mayor/register-applications',
        element: <ApplicationRegister />,
        roles: [APP_PERMISSION.MAYOR],
    },
    {
        path: 'mayor/applications/:id/edit',
        element: <ApplicationRegister />,
        roles: [APP_PERMISSION.MAYOR],
    },
    {
        path: 'mayor/register-requests',
        element: <RequestRegister />,
        roles: [APP_PERMISSION.MAYOR],
    },
    {
        path: 'mayor/requests/:id/edit',
        element: <RequestRegister />,
        roles: [APP_PERMISSION.MAYOR],
    },
    {
        path: 'mayor/contacts',
        element: <IPComReportList categoryType="CONTACT" />,
        roles: [APP_PERMISSION.MAYOR],
    },
    {
        path: 'mayor/contacts/:id',
        element: <IPComReportDetail categoryType="CONTACT" />,
        roles: [APP_PERMISSION.MAYOR],
    },
    {
        path: 'mayor/requests',
        element: <IPComReportList categoryType="REQUEST" />,
        roles: [APP_PERMISSION.MAYOR],
    },
    {
        path: 'mayor/requests/:id',
        element: <IPComReportDetail categoryType="REQUEST" />,
        roles: [APP_PERMISSION.MAYOR],
    },
    {
        path: 'mayor/attendance-meetings',
        element: <IPMeetingAttendanceList />,
        roles: [APP_PERMISSION.MAYOR],
    },
    {
        path: 'mayor/attendance-meetings/:id',
        element: <IPAttendanceDetail />,
        roles: [APP_PERMISSION.MAYOR],
    },
    {
        path: 'mayor/attendance-meetings/:id/create',
        element: <IPChangeAttendance type="create" />,
        roles: [APP_PERMISSION.MAYOR],
    },
    {
        path: 'mayor/attendance-meetings/:id/edit',
        element: <IPChangeAttendance type="edit" />,
        roles: [APP_PERMISSION.MAYOR],
    },
    {
        path: 'mayor/documents',
        element: <Documents type="mayor" />,
        roles: [APP_PERMISSION.MAYOR],
    },
    {
        path: 'mayor/applications',
        element: <IPComApplicationList />,
        roles: [APP_PERMISSION.MAYOR],
    },
    {
        path: 'mayor/applications/:id',
        element: <IPComApplicationQuestion />,
        roles: [APP_PERMISSION.MAYOR],
    },
];

export const representativeRoutes = [
    {
        path: 'representative/requests',
        element: <IPComReportList categoryType="REQUEST" />,
        roles: [APP_PERMISSION.REPRESENTATIVE],
    },
    {
        path: 'representative/requests/create',
        element: <RepresentativeRequest />,
        roles: [APP_PERMISSION.REPRESENTATIVE],
    },
    {
        path: 'representative/requests/:id/edit',
        element: <RepresentativeRequest />,
        roles: [APP_PERMISSION.REPRESENTATIVE],
    },
    {
        path: 'representative/requests/:id',
        element: <IPComReportDetail categoryType="REQUEST" />,
        roles: [APP_PERMISSION.REPRESENTATIVE],
    },
    {
        path: 'representative/applications',
        element: <IPComApplicationList />,
        roles: [APP_PERMISSION.REPRESENTATIVE],
    },
    {
        path: 'representative/applications/create',
        element: <RepresentativeApplication />,
        roles: [APP_PERMISSION.REPRESENTATIVE],
    },
    {
        path: 'representative/applications/:id/edit',
        element: <RepresentativeApplication />,
        roles: [APP_PERMISSION.REPRESENTATIVE],
    },
    {
        path: 'representative/applications/:id',
        element: <IPComApplicationQuestion />,
        roles: [APP_PERMISSION.REPRESENTATIVE],
    },
    {
        path: 'representative/attendance-meetings',
        element: <IPMeetingAttendanceList />,
        roles: [APP_PERMISSION.REPRESENTATIVE],
    },
    {
        path: 'representative/attendance-meetings/:id',
        element: <IPAttendanceDetail />,
        roles: [APP_PERMISSION.REPRESENTATIVE],
    },
    {
        path: 'representative/attendance-meetings/:id/create',
        element: <IPChangeAttendance type="create" />,
        roles: [APP_PERMISSION.REPRESENTATIVE],
    },
    {
        path: 'representative/attendance-meetings/:id/edit',
        element: <IPChangeAttendance type="edit" />,
        roles: [APP_PERMISSION.REPRESENTATIVE],
    },
    {
        path: 'representative/documents',
        element: <Documents type="representative" />,
        roles: [APP_PERMISSION.REPRESENTATIVE],
    },
];
export const accountRoutes = [
    {
        path: '',
        element: <AccountList />,
        roles: [APP_PERMISSION.LOCAl_GOV_COMMUNICATION, APP_PERMISSION.LOCAL_GOV_WELFARE],
    },
    {
        path: 'create',
        element: <CreateAccount />,
        index: true,
        roles: [APP_PERMISSION.LOCAl_GOV_COMMUNICATION, APP_PERMISSION.LOCAL_GOV_WELFARE],
    },
    {
        path: 'update/:id',
        element: <UpdateAccount />,
        index: true,
        roles: [APP_PERMISSION.LOCAl_GOV_COMMUNICATION, APP_PERMISSION.LOCAL_GOV_WELFARE],
    },
];
