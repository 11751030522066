import withTrans from '@/components/common/hocs/withTrans';
import PageComponent from '@/components/common/pages/PageComponent';
import PageTitle from '@/components/common/pages/PageTitle';
import TableBar from '@/components/common/table/TableBar';
import TableComponent from '@/components/common/table/TableComponent';
import { toast } from '@/components/ui/use-toast';
import useGetDataReport from '@/hooks/useGetDataReport';
import { ReportPageProps, ReportType } from '@/interfaces/report';
import { useAuthenStore } from '@/store/auth/AuthStore';
import { useCsvStore } from '@/store/csv/CsvStore';
import { TableProps } from 'antd';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { useNavigate } from 'react-router';
import { columns } from '@/pages/reports/components/Columns';
import { createFileContactReportCsv, getContactReportList } from '@/modules/services/contact.service';
import { createFileRequestReportCsv, getRequestReportList } from '@/modules/services/request.service';
import {
    createFileApplicationReportCsv,
    getApplicationReportList,
} from '@/modules/services/application.report.service';
import { REPORT_ROUTES } from '@/constants/appConstants';
import { convertFileNameCsv } from '@/lib/helper';
import { Params } from '@/interfaces/common/global';

const getData = (is_welfare = false) => ({
    [REPORT_ROUTES.CONTACT]: {
        get: getContactReportList,
        create_csv: createFileContactReportCsv,
    },
    [REPORT_ROUTES.REQUEST]: {
        get: (params?: Params) => getRequestReportList({ ...params, is_welfare }),
        create_csv: () => createFileRequestReportCsv({ is_welfare }),
    },
    [REPORT_ROUTES.COMMUNICATION_APP]: {
        get: getApplicationReportList,
        create_csv: createFileApplicationReportCsv,
    },
});

const ReportList = (props: ReportPageProps) => {
    const { t, categoryType, is_welfare = false } = props;
    const functionGetData = getData(is_welfare);
    const { user } = useAuthenStore();
    const navigate = useNavigate();
    const { addQueue } = useCsvStore();
    const [page, setPage] = useState(1);
    const type = REPORT_ROUTES[categoryType];
    const { isDownLoadCsv, dataSource, total, isLoading } = useGetDataReport({
        page,
        userId: user?.id,
        callApi: functionGetData[type].get,
    });
    const onDownloadCsv = async () => {
        try {
            await functionGetData[type].create_csv();
            addQueue({
                flag: `${type}_report`,
                status: 'pending',
                url: `users/export_csv_report_${type}`,
                fileName: convertFileNameCsv({ prefixName: t(`report.${type}_title`) }),
            });
            toast({ description: t('common.MSG_045'), variant: 'success' });
        } catch (error) {
            toast({ description: t('common.MSG_040'), variant: 'destructive' });
        }
    };

    const onEdit = (key: string) => {
        navigate(`${key}/edit`);
    };

    const onDetail = (key: string) => {
        navigate(`${key}`);
    };

    const onCreate = () => {
        navigate(`create`);
    };

    const onChangeTable: TableProps<ReportType>['onChange'] = (pagination) => {
        setPage(pagination.current);
    };
    return (
        <PageComponent
            title={<PageTitle routes={[{ path: '', breadcrumbName: t(`report.${type}_title`) }]} className="py-6" />}
            isDownLoadCsv={isDownLoadCsv}
            onDownLoadCsv={onDownloadCsv}
        >
            <TableBar hasSelect={false} isCreate={true} onCreate={onCreate} textCreate={t('report.proxy_register')} />
            <TableComponent
                loading={isLoading}
                columns={columns({ t, onEdit, onDetail, title: type, is_welfare })}
                dataSource={dataSource}
                onChange={onChangeTable}
                pagination={{
                    total: total,
                    current: page,
                }}
            />
        </PageComponent>
    );
};

export default withTrans(observer(ReportList));
